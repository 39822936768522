import styled from 'styled-components'
import { mcText } from '../utils'
import { NotConvincedBanner } from './NotConvinced'
import { Plan } from './Plan'
import { Discount } from './Discount'

const Section = styled('section')({
  width: '100%',
  backgroundColor: 'var(--col-secondary)',
  color: 'var(--col-text)',
  display: 'flex',
  flexDirection: 'column',
  paddingTop: 50,
  paddingBottom: 50,
  position: 'relative',
  '& + .trial-banner': {
    color: 'var(--col-secondary)',
    backgroundColor: 'var(--col-text)',
  },
})

const Title = styled('h3')({
  ...mcText(),
  fontSize: 30,
  paddingBottom: '16px',
})

const Container = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  gap: '12px',
  flexDirection: 'row',
  paddingTop: 12,
})

const Trustbox = styled('div')({
  paddingTop: 24,
})

export const Plans = () => {
  return <>
    <Section id="get-started">
      <Title>
        <p>Get Started</p>
      </Title>
      <Container>
        <Plan
          id={'minecraft/4gb'}
          name={'4GB'}
          features={[
            'Redline Panel',
            '2 Threads @ 4.2GHz',
            'Unmetered NVMe Storage',
            'Unmetered 1Gbps Port',
            'Optional Free Subdomain',
          ]}
          price={500}
          color={[176, 46, 38]}
        />
        <Plan
          id={'minecraft/6gb'}
          name={'6GB'}
          features={[
            'Redline Panel',
            '3 Threads @ 4.2GHz',
            'Unmetered NVMe Storage',
            'Unmetered 1Gbps Port',
            'Optional Free Subdomain',
          ]}
          price={750}
          color={[176, 46, 38]}
        />
        <Plan
          id={'minecraft/8gb'}
          name={'8GB'}
          features={[
            'Redline Panel',
            '4 Threads @ 4.2GHz',
            'Unmetered NVMe Storage',
            'Unmetered 1Gbps Port',
            'Optional Free Subdomain',
          ]}
          price={1000}
          color={[176, 46, 38]}
        />
        <Plan
          id={'minecraft/10gb'}
          name={'10GB'}
          features={[
            'Redline Panel',
            '4.5 Threads @ 4.2GHz',
            'Unmetered NVMe Storage',
            'Unmetered 1Gbps Port',
            'Optional Free Subdomain',
          ]}
          price={1250}
          color={[176, 46, 38]}
        />
        <Plan
          id={'minecraft/12gb'}
          name={'12GB'}
          features={[
            'Redline Panel',
            '5 Threads @ 4.2GHz',
            'Unmetered NVMe Storage',
            'Unmetered 1Gbps Port',
            'Optional Free Subdomain',
          ]}
          price={1500}
          color={[176, 46, 38]}
        />
        <Plan
          id={'minecraft/16gb'}
          name={'16GB'}
          features={[
            'Redline Panel',
            '6 Threads @ 4.2GHz',
            'Unmetered NVMe Storage',
            'Unmetered 1Gbps Port',
            'Optional Free Subdomain',
          ]}
          price={2000}
          color={[176, 46, 38]}
        />
      </Container>
      <Trustbox className="trustpilot-widget" data-locale="en-GB" data-template-id="5419b6a8b0d04a076446a9ad" data-businessunit-id="625e3a2db050587e24cc9fcf" data-style-height="24px" data-theme="dark" data-min-review-count="1">
        <a href="https://uk.trustpilot.com/review/redlinehosting.net" target="_blank" rel="noopener noreferrer">Trustpilot</a>
      </Trustbox>
    </Section>
    <NotConvincedBanner />
  </>
}
