import styled from 'styled-components'
import { getPlanLink, mcText } from '../utils'
import { Button } from './Button'

export interface IPlan {
  id: string,
  name: string,
  price: number,
  features: string[],
  color: [number, number, number],
  recommended?: boolean,
}

const Container = styled('div')({
  textAlign: 'center',
  width: 'min(100%, 350px)',
  color: 'var(--col-text)',
})

const Header = styled('div')({
  height: 50,
  ...mcText(),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 20,
})

const Features = styled('ul')({
  listStyleType: 'none',
  padding: 0,
  margin: 0,
})

const Feature = styled('li')({
  height: 45,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  ':nth-child(odd)': {
    backgroundColor: 'rgba(120, 120, 120, 0.1)',
  },
})

const Footer = styled('div')({
  height: 80,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 25px',
  fontSize: 20,
  fontWeight: 'bold',
  position: 'relative',
  '& > button, & > a': {
    padding: '10px 20px',
  },
  '& > div': {
    display: 'flex',
    textAlign: 'left',
    flexDirection: 'column',
    '& > span': {
      fontSize: 12,
      fontWeight: 'normal',
    },
  },
})

export const Plan: React.FC<IPlan> = ({
  id,
  name,
  price,
  features,
  color,
  recommended,
}) => {
  const url = getPlanLink(id)

  return (
    <Container style={{
      backgroundColor: `rgba(${color.join(', ')}, 0.2)`,
      transform: recommended ? 'scale(1.1)' : undefined,
    }}>
      <Header style={{ backgroundColor: `rgb(${color.join(', ')})` }}>
        {name}
      </Header>
      <Features>
        {features.map(feature => (
          <Feature key={feature}>{feature}</Feature>
        ))}
      </Features>
      <Footer style={{ backgroundColor: `rgb(${color.join(', ')})` }}>
        <div>
          <p>&#163;{price / 100}</p>
          <span>monthly</span>
        </div>
        <Button style={{ backgroundColor: 'rgb(249, 128, 29)'}} component='a' href={url}>Order {name} Plan</Button>
      </Footer>
    </Container>
  )
}
