import styled from 'styled-components'
import { Logo } from './Logo'

import {
  SiDiscord, SiTwitter,
} from 'react-icons/si'
import { breakpoint } from '../utils'

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'var(--col-secondary)',
  color: 'var(--col-text)',
  padding: '0 50px',
  height: 200,
  textAlign: 'center',
  [breakpoint('md')]: {
    flexDirection: 'row',
    textAlign: 'left',
    justifyContent: 'space-between',
  },
})

const Left = styled('div')({
  display: 'flex',
  flexDirection: 'column',
})

const Copyright = styled('p')({

})

const Notice = styled('p')({
  fontWeight: '100',
  
})

const Right = styled('div')({
  display: 'flex',
  flexDirection: 'column',
})

const Socials = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 20,
  marginBottom: 10,
  [breakpoint('md')]: {
    margin: 0,
    justifyContent: 'flex-end',
  },
})

const Social = styled('a')({
  color: 'var(--col-text)',
  '& > svg': {
    width: '2em',
    height: '2em',
  },
  '& + &': {
    marginLeft: 30,
  },
})

const Discord = styled(Social)({
  '&:hover, &:focus': {
    color: '#5865F2',
  },
})

const Twitter = styled(Social)({
  '&:hover, &:focus': {
    color: '#1D9BF0',
  },
})

const TermLinks = styled('div')({
  marginTop: 10,
  '& > a': {
    '&:hover': {
      textDecoration: 'underline',
    },
    '& + a': {
      marginLeft: 20,
    },
  },
})

export const Footer = () => {
  return (
    <Container>
      <Left>
        <Logo />
        <Copyright>&copy; Redline Hosting {new Date().getFullYear()}</Copyright>
        <Notice>Minecraft® is a registered trademark of Mojang AB. This website is not affiliated with or endorsed by Mojang AB.</Notice>
      </Left>
      <Right>
        <Socials>
          <Discord href="https://redlinehosting.net/discord" aria-label="Join Discord Server"><SiDiscord /></Discord>
          <Twitter href="https://twitter.com/Hosting_Redline" aria-label="Visit Twitter Page"><SiTwitter /></Twitter>
        </Socials>
        <TermLinks>
          <a href="/terms/terms-of-service">Terms of Service</a>
          <a href="/terms/privacy-policy">Privacy Policy</a>
        </TermLinks>
      </Right>
    </Container>
  )
}