import { HiCheck } from 'react-icons/hi'
import styled from 'styled-components'
import { breakpoint, mcText, scrollTo } from '../utils'
import { Button } from './Button'
import { TrialBanner } from './TrialBanner'

const Container = styled('section')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  '& > .trial-banner': {
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(176, 46, 38, 0.1)',
    zIndex: -1,
  },
})

const Background = styled('picture')({
  '& > source, & > img': {
    width: '100vw',
    height: '100vh',
    objectFit: 'cover',
    left: 0,
    top: 0,
    zIndex: -1,
    filter: 'blur(7px)',
  },
})

const Overlay = styled('div')({
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  color: 'var(--col-text)',
  textAlign: 'center',
  width: '90vw',
  [breakpoint('sm')]: {
    width: '80vw',
  },
  [breakpoint('md')]: {
    width: '70vw',
  },
  [breakpoint('lg')]: {
    width: '60vw',
  },
  [breakpoint('xl')]: {
    width: '50vw',
  },
})

const Features = styled('ul')({
  margin: '20px 0',
  padding: 0,
  listStyleType: 'none',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  columnGap: 30,
  textAlign: 'left',
  '& > li': {
    padding: '10px 0',
    height: 45,
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      color: 'var(--col-secondary)',
      height: 20,
      width: 20,
      strokeWidth: 2,
      marginRight: 10,
    },
  },
})

const SquareText = styled('h2')({
  ...mcText(),
  fontSize: '2.8rem',
})

const CTA = styled(Button)({
  fontSize: '1rem',
})

export const Hero = () => {
  const features = [
    'Blazing Fast Hardware', '24/7 Uptime',
    'Unlimited Bandwidth', 'Fast & Personal Support',
    '1 Click Plugin Installer', 'Instant Deployment',
    'Upgrade At Any Time', 'Unlimited Player Slots',
  ]

  return (
    <Container id="top">
      <Background>
        <source srcSet="/assets/img/bg-compressed.webp" type="image/webp" />
        <source srcSet="/assets/img/bg-compressed.avif" type="image/avif" />
        <img src="/assets/img/bg-compressed.png" alt="background" />
      </Background>
      <Overlay>
        <SquareText>Quality Minecraft Hosting</SquareText>
        <p>...at affordable prices. We pride ourselves on offering a premium service while maintaining affordable prices; we don&apos;t want to be responsible for burning a hole in your pocket. Get your dream Minecraft server today!</p>
        <Features>
          {features.map((feature, i) => (
            <li key={i}><HiCheck style={{color: 'rgb(249, 128, 29)'}}/>{feature}</li>
          ))}
        </Features>
        <CTA onClick={() => scrollTo('get-started')}>Get Started</CTA>
      </Overlay>
      {/* <TrialBanner /> */}
    </Container>
  )
}
