import styled from 'styled-components'
import { breakpoint } from '../utils'

const unit = 10
const Container = styled('div')({
  width: 400,
  maxWidth: '90vw',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: 25,
  [breakpoint('md')]: {
    width: '100%',
    aspectRatio: 'unset',
  },
  [breakpoint('lg')]: {
    width: 400,
    maxWidth: 'calc(100% / 3.5)',
    height: 350,
  },
  [breakpoint('xxl')]: {
    width: 400,
    height: 'unset',
    aspectRatio: '4 / 4',
  },
  '.top, .bottom, .left, .right': {
    position: 'absolute',
    backgroundColor: 'var(--col-text)',
  },
  '.top, .bottom': {
    width: '100%',
    height: unit,
    left: 0,
  },
  '.left, .right': {
    height: '100%',
    width: unit,
    top: 0,
  },
  '.top': {
    top: -unit,
  },
  '.bottom': {
    bottom: -unit,
  },
  '.left': {
    left: -unit,
  },
  '.right': {
    right: -unit,
  },
})

const Icon = styled('div')({
  height: 60,
  aspectRatio: '1 / 1',
  marginBottom: 30,
  '& > svg': {
    width: '100%',
    height: '100%',
  },
})

const Title = styled('h4')({
  fontSize: 22,
})

const Desc = styled('p')({
  fontSize: 16,
  marginTop: 16,
})

interface IReason {
  icon: JSX.Element,
  title: string,
  desc: string,
}

export const Reason: React.FC<IReason> = ({
  icon,
  title,
  desc,
}) => {
  return (
    <Container>
      <span className="top"></span>
      <span className="bottom"></span>
      <span className="left"></span>
      <span className="right"></span>
      <Icon>{icon}</Icon>
      <div>
        <Title>{title}</Title>
        <Desc>{desc}</Desc>
      </div>
    </Container>
  )
}
