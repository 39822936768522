import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { RiArrowDownSFill } from 'react-icons/ri'
import { mcText, scrollTo, useMediaQuery } from '../utils'
import { Logo } from './Logo'

const Container = styled('div')({
  height: 'var(--header-height)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 30px',
  position: 'fixed',
  width: '100%',
  top: 0,
  left: 0,
  zIndex: 9,
  transition: 'all 200ms linear',
  '&.solid': {
    backgroundColor: 'var(--col-secondary)',
  },
})

const NavItems = styled('ul')({
  padding: 0,
  display: 'flex',
  color: 'var(--col-text)',
})

const NavItem = styled('li')({
  ...mcText(true, 'text', 'accent'),
  listStyleType: 'none',
  cursor: 'pointer',
  '& + &': {
    marginLeft: 30,
  },
})

const PanelBtn = styled(NavItem)({
  ...mcText(true, 'primary', 'accent'),
  marginLeft: '60px !important',
})

const MobileNavBtn = styled('button')({
  color: 'var(--col-text)',
  backgroundColor: 'transparent',
  border: 'none',
  padding: 0,
  '& > svg': {
    width: 22,
    height: 22,
  },
})

const MobileNav = styled('div')({
  position: 'fixed',
  top: 0,
  left: 0,
  minHeight: 250,
  width: '100vw',
  backgroundColor: 'var(--col-secondary)',
  zIndex: 8,
  paddingTop: 'var(--header-height)',
  fontSize: 20,
  '& > ul': {
    flexDirection: 'column',
    alignItems: 'center',
    '& > li': {
      margin: '0 !important',
      padding: '20px 0',
    },
  },
})

const MobileNavOverlay = styled('div')({
  position: 'fixed',
  top: 0,
  left: 0,
  height: '100vh',
  width: '100vw',
  zIndex: 7,
  backgroundColor: 'rgba(25, 25, 25, 0.9)',
})

export const Header = () => {
  const inlineNav = useMediaQuery('md')
  const [mobileNavOpen, setMobileNavOpen] = useState(false)
  const [isSolid, setSolid] = useState(false)

  useEffect(() => {
    document.addEventListener('scroll', function (e: any) {
      setSolid(!!window.scrollY)
    })
  }, [setSolid])

  function gotoPanel() {
    window.location.href = 'https://panel.redlinehosting.net'
  }

  function gotoBilling() {
    window.location.href = 'https://billing.redlinehosting.net'
  }

  function goto(id: string) {
    scrollTo(id)
    setMobileNavOpen(false)
  }

  const Nav = () => (
    <NavItems>
      <NavItem onClick={() => goto('why-us')}>Why Us</NavItem>
      <NavItem onClick={() => goto('get-started')}>Get Started</NavItem>
      <PanelBtn onClick={gotoBilling}>Billing</PanelBtn>
      <PanelBtn onClick={gotoPanel}>Panel</PanelBtn>
    </NavItems>
  )

  return <>
    <Container className={isSolid ? 'solid' : ''}>
      <Logo />
      {inlineNav && <Nav />}
      {!inlineNav && (
        <MobileNavBtn onClick={() => setMobileNavOpen(!mobileNavOpen)} aria-label="Open Navigation">
          <RiArrowDownSFill />
        </MobileNavBtn>
      )}
    </Container>
    {mobileNavOpen && <>
      <MobileNavOverlay onClick={() => setMobileNavOpen(false)} />
      <MobileNav>
        <Nav />
      </MobileNav>
    </>}
  </>
}
