import styled from 'styled-components'
import { mcText, shadeColor } from '../utils'

const unit = 5
const btnCol = '#b02e26'
const shade = 15

const styles = {
  ...mcText(),
  backgroundColor: btnCol,
  fontSize: '0.8rem',
  border: 'none',
  width: 'auto',
  padding: '15px 50px',
  position: 'relative',
  cursor: 'pointer',
  boxShadow: 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
  '.top, .bottom, .left, .right': {
    position: 'absolute',
    backgroundColor: shadeColor(btnCol, -shade),
  },
  '.top, .bottom': {
    width: '100%',
    height: unit,
    left: 0,
  },
  '.left, .right': {
    height: '100%',
    width: unit,
    top: 0,
  },
  '.top': {
    top: -unit,
    backgroundColor: shadeColor(btnCol, -(shade / 2)),
  },
  '.bottom': {
    bottom: -unit,
  },
  '.left': {
    left: -unit,
  },
  '.right': {
    right: -unit,
  },
  '&:hover': {
    boxShadow: 'none',
  },
}

const ContainerButton = styled('button')({
  ...styles,
})

const ContainerA = styled('a')({
  ...styles,
})

type Props = ButtonProps & AProps

interface IButton extends Props {
  component?: 'button' | 'a',
}

type ButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>

type AProps = React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>

export const Button: React.FC<IButton> = ({
  children,
  component,
  ...props
}) => {
  component = component || 'button'

  const Container: React.ComponentType = component === 'button' ? ContainerButton : ContainerA

  return (
    <Container {...props as any}>
      {children}
    </Container>
  )
}
