import styled from 'styled-components'
import { scrollTo } from '../utils'

const Title = styled('h1')({
  fontFamily: '"Roboto", sans-serif',
  color: 'var(--col-primary)',
  fontWeight: 'bold',
  textUnderlineOffset: 2,
  cursor: 'pointer',
  '& > span': {
    color: 'var(--col-text)',
    fontWeight: 'normal',
  },
})

export const Logo = () =>
  <Title onClick={() => scrollTo('top')}>REDLINE<span>HOSTING</span></Title>