import styled from 'styled-components'
import { breakpoint, mcText } from '../utils'
import { Reason } from './Reason'
import { RiServerFill, RiTimeFill, RiUser2Fill } from 'react-icons/ri'

const Container = styled('section')({
  width: '100%',
  backgroundColor: '#1A1A1A',
  color: 'var(--col-text)',
  display: 'flex',
  flexDirection: 'column',
  paddingTop: 50,
  paddingBottom: 80,
})

const Title = styled('h3')({
  ...mcText(false, 'text'),
  fontSize: 30,
})

const Reasons = styled('div')({
  display: 'flex',
  color: 'white',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: 50,
  '& > div + div': {
    marginTop: 40,
  },
  [breakpoint('lg')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& > div + div': {
      marginTop: 0,
    },
  },
})

export const WhyChooseUs = () => {
  return (
    <Container id="why-us">
      <Title>Why Choose Us</Title>
      <Reasons>
        <Reason
          icon={<RiServerFill />}
          title="Hand picked hardware."
          desc="We carefully select the best hardware for our nodes to ensure that your server is running perfectly all day, every day."
        />
        <Reason
          icon={<RiTimeFill />}
          title="No random, unexplained downtime."
          desc="You and your players will have access to your server 99% of the time. No random downtime, no random restarts."
        />
        <Reason
          icon={<RiUser2Fill />}
          title="Friendly, personal support."
          desc="No support bots here! We pride ourselves on offering friendly, personal support to get you out of those stickiest of situations."
        />
      </Reasons>
    </Container>
  )
}
