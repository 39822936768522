import { useMediaQuery as uMQ } from 'react-responsive'

type Col = 'text' | 'primary' | 'secondary' | 'accent'

export function mcText(hover = false, col: Col = 'text', hovCol: Col = 'primary'): any {
  return {
    color: `var(--col-${col})`,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    transition: 'all 200ms linear',
    '&:hover': hover ? {
      color: `var(--col-${hovCol})`,
    } : undefined,
  }
}

export function getPlanLink(planId: string) {
  return `https://billing.redlinehosting.net/index.php?rp=/store/${planId}`
}

export const trialLink = 'https://billing.redlinehosting.net/cart.php?a=add&pid=7&skipconfig=1'

export function shadeColor(color: string, percent: number) {

  let R = parseInt(color.substring(1, 3), 16)
  let G = parseInt(color.substring(3, 5), 16)
  let B = parseInt(color.substring(5, 7), 16)

  R = parseInt(R * (100 + percent) / 100 as any)
  G = parseInt(G * (100 + percent) / 100 as any)
  B = parseInt(B * (100 + percent) / 100 as any)

  R = (R < 255) ? R : 255
  G = (G < 255) ? G : 255
  B = (B < 255) ? B : 255

  const RR = ((R.toString(16).length == 1) ? '0' + R.toString(16) : R.toString(16))
  const GG = ((G.toString(16).length == 1) ? '0' + G.toString(16) : G.toString(16))
  const BB = ((B.toString(16).length == 1) ? '0' + B.toString(16) : B.toString(16))

  return `#${RR}${GG}${BB}`
}

export function scrollTo(id: string) {
  const yOffset = -100
  const element = document.getElementById(id) as any
  const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset

  window.scrollTo({top: y, behavior: 'smooth'})
}

type Breakpoint = 'sm' | 'md' | 'lg' | 'xl' | 'xxl'

const breakpoints: {
  [k in Breakpoint]: string
} = {
  sm: '(min-width: 576px)',
  md: '(min-width: 768px)',
  lg: '(min-width: 992px)',
  xl: '(min-width: 1200px)',
  xxl: '(min-width: 1400px)',
}

export const breakpoint = (bp: Breakpoint) => `@media ${breakpoints[bp]}`

export const useMediaQuery = (bp: Breakpoint) => uMQ({
  query: breakpoints[bp],
})
