import styled from 'styled-components'
import { breakpoint, mcText, trialLink } from '../utils'
import { Button } from './Button'

const Container = styled('div')({
  width: '100vw',
  backgroundColor: '#1A1A1A',
  color: 'black',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '20px 0',
  flexDirection: 'column',
  [breakpoint('md')]: {
    height: 100,
    padding: '0 50px',
    flexDirection: 'row',
  },
})

const Buttons = styled('div')({
  display: 'flex',
  gap: 16,
})

const Left = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 20,
  '& > span': {
    display: 'none',
  },
  '& > h3': {
    ...mcText(false, 'accent'),
    marginTop: 5,
    fontSize: '1.5rem',
  },
  [breakpoint('md')]: {
    justifyContent: 'flex-start',
    margin: 0,
    '& > span': {
      display: 'block',
    },
  },
})

export const NotConvincedBanner = () => {
  return (
    <Container>
      <Left>
        <span style={{ color: 'white' }}>Still not convinced?</span>
        <h3>Here is what you can do!</h3>
      </Left>
      <Buttons>
        {/* <Button component="a" href={trialLink}>Try for Free</Button> */}
        <Button component="a" target={'_blank'} rel={'norefferer noopener'} href={'https://redlinehosting.net/discord'}>Talk to Us</Button>
      </Buttons>
    </Container>
  )
}
