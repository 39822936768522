import { Footer, Header, Hero, Plans, WhyChooseUs } from './components'

export const App = () => {
  return <>
    <Header />
    <Hero />
    <WhyChooseUs />
    <Plans />
    <Footer />
  </>
}
